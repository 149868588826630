import React, { useState } from "react";
import "./Pricing.css";
import { Button } from "../../../Button";
import Switch from "react-ios-switch";
import { connect } from "react-redux";
import { addToCarT } from "../../../Redux/action";
import { data } from "autoprefixer";

function Pricing(props) {
  console.log("pricing", props);
  const [checked, setChecked] = useState(false);
  return (
    <div className="pricing-container" id="pricing">
      <div className="top-wrapper">
        <div className="topline">Our Pricing</div>
        <div className="title">
          Pay securely online and manage the booking via desktop or mobile app.
        </div>
        <div
          className="switch"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <p style={{ fontSize: 16, fontWeight: 500 }}>Monthly</p>
          <div style={{ marginLeft: 15, marginRight: 15 }}>
            <Switch
              style={{ width: 55 }}
              offColor="rgba(231, 236, 255, 1)"
              onColor="rgba(231, 236, 255, 1)"
              handleColor="#4183EC"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
          </div>
          <p style={{ fontSize: 16, fontWeight: 500 }}>Annualy</p>
        </div>
      </div>
      {/* <Switch /> */}
      {/* <div className="flex">
        <button
          onClick={() =>
            props.addToCarTHandler({ price: 1000, name: "I Phone" })
          }
        >
          ADD TO CART
        </button>
        <div>{props.data}</div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignSelf: "center",
        }}
        className="pricing-card"
      >
        <div class="card-basic">
          <div class="card-header header-basic">
            <div>Orgzstack Base</div>
          </div>
          <div class=" header-description">
            <p>The national average cost of buying coin easy.</p>
          </div>
          <div class="card-body">
            <p
              className="price"
              style={{ color: "rgba(150, 150, 153, 1)", marginBottom: 30 }}
            >
              <span
                style={{
                  fontSize: 40,
                  fontWeight: 700,
                  color: "rgba(43, 66, 157, 1)",
                }}
              >
                {checked ? "$10/year" : " $1/month"}
              </span>{" "}
              {/* /month */}
            </p>
            <Button buttonSize="btn--widerr" buttonColor="lightblue">
              <span style={{ fontSize: 14, fontWeight: "700" }}>
                Select Plan
              </span>
            </Button>
            <div class="card-element-hidden-basic" style={{ margin: 15 }}>
              <ul class="card-element-container">
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Companies setup</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Organisation Setup</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Business Units Setup</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Employee Setup</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Dashboards</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Directory</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Organisation structure</li>
                </div>
              </ul>
              {/* <button class="btn btn-basic">Order now</button> */}
            </div>
          </div>
        </div>

        <div class="card-standard">
          <div class="card-header header-standard">
            <div>Orgzstack Time Pro</div>
          </div>
          <div class=" header-description-standard">
            <p>The national average cost of buying coin easy.</p>
          </div>
          <div class="card-body">
            <p
              className="price"
              style={{ color: "rgba(239, 239, 239, 1)", marginBottom: 30 }}
            >
              <span style={{ fontSize: 40, fontWeight: 700, color: "#fff" }}>
                {checked ? "$20/year" : " $2/month"}
              </span>{" "}
              {/* /month */}
            </p>
            <Button buttonSize="btn--widerr" buttonColor="white">
              <span style={{ fontSize: 14, fontWeight: "700" }}>
                Select Plan
              </span>
            </Button>
            <div class="card-element-hidden-standard" style={{ margin: 15 }}>
              <ul class="card-element-container-standard">
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Unlimited collections</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Worldwide accessbiliy</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Unlimited automation actions</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Access all features</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">24 hours support</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Sync accross devices</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/white-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Share with more 5 users</li>
                </div>
              </ul>
              {/* <button class="btn btn-standard">Order now</button> */}
            </div>
          </div>
        </div>
        <div class="card-premium">
          <div class="card-header header-premium">
            <div>Orgzstack E-lifeline</div>
          </div>
          <div class=" header-description">
            <p>The national average cost of buying coin easy.</p>
          </div>
          <div class="card-body">
            <p
              className="price"
              style={{ color: "rgba(150, 150, 153, 1)", marginBottom: 30 }}
            >
              <span
                style={{
                  fontSize: 40,
                  fontWeight: 700,
                  color: "rgba(43, 66, 157, 1)",
                }}
              >
                {checked ? "$30/year" : " $3/month"}
              </span>{" "}
              {/* /month */}
            </p>
            <Button buttonSize="btn--widerr" buttonColor="lightblue">
              <span style={{ fontSize: 14, fontWeight: "700" }}>
                Select Plan
              </span>
            </Button>
            <div class="card-element-hidden-premium" style={{ margin: 15 }}>
              <ul class="card-element-container">
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Unlimited collections</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Worldwide accessbiliy</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Unlimited automation actions</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Access all features</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">24 hours support</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Sync accross devices</li>
                </div>
                <div class="card-elememt-main" style={{ display: "flex" }}>
                  <img
                    src={require("../PricingPage/purple-tick.svg").default}
                    height={16}
                  />
                  <li class="card-element">Share with more 10 users</li>
                </div>
              </ul>
              {/* <button class="btn btn-premium">Order now</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default Pricing;

const mapStateToProps = (state) => ({
  cartdata: state.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  addToCarTHandler: (data) => dispatch(addToCarT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
