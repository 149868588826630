import { tab } from "@testing-library/user-event/dist/tab";
import React, { useState, useRef } from "react";
import "./Tabs.css";
import { Button } from "../../../Button";
import { margin } from "@mui/system";
import { Hidden } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import HorizontalScroll from "react-horizontal-scrolling";
import emailjs from "@emailjs/browser";
import KeyboardDoubleArrowUpSharpIcon from "@mui/icons-material/KeyboardDoubleArrowUpSharp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  height: 490,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const Tab4 = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const tabs = [
    {
      id: 1,
      taber: "tab1",
      tabTitle: "Multiple Dashboard Reports",
      headimg: "images/inside6.svg",
      titledescription:
        "Empower your business with insightful and intuitive dashboard reports designed for informed and smarter decision making. Gain real-time business intelligence about your team’s productivity, engagement, attendance and leaves in one glance to make quicker, data-driven decisions. ",
      smallheading1: "Build a productivity-driven culture with a",
      heading1: "Comprehensive Productivity Management System",
      content1:
        "View individual or team productivity metrics and workforce data analytics such as productive hours, idle hours and break hours in a single view. Optimise your workforce and increase accountability among employees with an intelligent attendance capturing system powered with geo-tagging and geo-fencing.",
      img1: "images/multi1.svg",
      smallheading2: "End-to-end attendance management",
      heading2: "Track. Monitor. Improve.",
      content2:
        "Create a stable and predictable workforce with our cloud-based attendance management system. Establish different sets of attendance and holiday protocols as per your organisational needs, approve/reject leaves, regularise attendance to keep a tight grip on your team’s productivity.",
      img2: "images/multi2.svg",
      smallheading3: "Implement complex policies with our",
      heading3: "Flexible Leave Management Software",
      content3:
        "Keeping larger organisation’s need for extreme flexibility in mind, Orgzstack was built to accommodate hundreds of different attendance and leave configurations to enable companies to stay compliant to their versatile needs. Check the availability of your entire team and allocate resources better to eliminate all redundancies and improve overall productivity.",
      img3: "images/multi3.svg",
      smallheading4: "Achieve your organisational goals with",
      heading4: "Reports, Insights & more",
      content4:
        "From managers to HRs to employees, streamline everyone’s workflow, drive performance across teams and optimise resource utilisation with standardised and custom attendance and leave data reports.",
      img4: "images/multi5.svg",
      titleColor: "rgba(65, 131, 236, 1)",
      class: "tab1",
    },
    {
      id: 2,
      taber: "tab2",
      tabTitle: "Monitor and Manage On-field Employees",
      headimg: "images/inside1.svg",
      titledescription:
        "Communicating effectively, even while being on field, is critical to an organisation’s success. Orgzstack’s comprehensive Employee Monitoring and Time Tracking Solution enables companies to monitor and manage all employee activities, whether they’re on-field or in a client meeting without sacrificing efficiency, clarity, or productivity.",
      smallheading1: "A centralised repository to",
      heading1: "Record Client Meetings",
      content1:
        "Capture the finest details of a client meeting with our easy-to-use ‘Client Meeting manager’. Enable your team to clock-in at the client location and record every important detail of a meeting including their contact information, the MoM, time taken to travel to-and-forth, and so on.  ",
      img1: "images/inside4.svg",
      smallheading2: "Advanced geotagging solutions to",
      heading2: "Authenticate Employee Movement",
      content2:
        "Monitor the movement of your on-field employees instead of guessing, and capture detailed data on working hours, travel hours, time-in and time-out, and much more. Regulate time theft on field and increase productivity using the power of geotagging and geofencing.",
      img2: "images/inside5.svg",
      smallheading3: "Capture and monitor the",
      heading3: "Essence of Every Meeting",
      content3:
        "Alongside capturing the date, time and contact information of every client meeting, we’ve also added a feature that encourages employees to record the minutes of the meeting immediately after they’re done. This minimises the loss of information usually caused when MoMs are sent out days or even hours after the meeting. ",
      img3: "images/inside2.svg",
      smallheading4: "Designed to enable",
      heading4: "Efficient Meeting Management",
      content4:
        "A meeting management system to keep track of all your scheduled meetings (past and upcoming) with automated reminders in a simplified calendar view. Eliminate common blunders such as double booking or missing out on an appointment by simply using the ‘Client Meeting Manager’.",
      img4: "images/inside3.svg",
      titleColor: "rgba(239, 162, 47, 1)",
      class: "tab2",
    },
    {
      id: 3,
      taber: "tab3",
      tabTitle: "Managing Modern Workspaces-WFH and Remote Employees",
      headimg: "images/manage1.svg",
      titledescription:
        "Improve the efficiency of your workforce irrespective of how distributed they are. Our adaptive remote workforce solution optimises performance tracking, collaboration, and employee engagement with modern HR technology designed to prepare organisations for the present and future.",
      smallheading1: "Remote Productivity Tracking with",
      heading1: "Systematically Captured Screenshots",
      content1:
        "Track remote productivity with detailed  reports on employee activities by means of screenshots, web and application usage history, etc. and get rid of the scepticism surrounding remote working. Our intuitive timesheet platform bridges the gap between in-office and remote employees and powers them with tools to collaborate on a common platform.",
      img1: "images/manage2.svg",
      smallheading2: "Detailed reports on employee’s",
      heading2: "Web and Application Usage History",
      content2:
        "Companies unequipped with the tools required to keep track of employee’s online activities tend to feel nervous about the new-age remote working culture. Say goodbye to all such woes with Orgzstack’s simplified tracking tools including detailed reports on web and application usage history",
      img2: "images/manage3.svg",
      smallheading3: "Know what your remote team is doing with",
      heading3: "Productivity and Performance Reports",
      content3:
        "In an attempt to eliminate all ambiguity about remote employee’s productivity and performance, we churn real-time data to generate insightful reports such as daily average productivity, daily, weekly and monthly performance reports and so on.",
      img3: "images/manage4.svg",
      //   heading4: "Title 1",
      //   content4:
      //     "Las tabs se generan automáticamenteorem ipsum dolor sit amet, consectetur adipiscingrem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri elit.rem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique sed vulputate tincidunt in. Mattis est turpis sit dui a partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.",
      //   img4: "images/manage4.svg",
      titleColor: "rgba(109, 84, 208, 1)",
      class: "tab3",
    },
    {
      id: 4,
      taber: "tab4",
      tabTitle: "Roster Management",
      headimg: "images/roster1.svg",
      titledescription:
        "Organisations with a sizeable workforforce commonly find manual maintenance of time sheets to be an operational headache. Orgzstack’s Roster Management System is designed to digitise messy shift scheduling and timesheet consolidation, maintain and monitor multiple working shifts, streamline operations and optimise labour costs among mid-sized and large organisations.",
      smallheading1: "Schedule faster and better with",
      heading1: "Supercharged Shift Scheduling ",
      content1:
        "Create multiple shifts quickly and accurately, improve your schedule efficiency while keeping an eye on the budget in real-time. Ensure seamless team communication regarding any last minute updates made to schedules, and gain access to valuable information about staff productivity, schedules, timesheets and more.",
      img1: "images/roster2.svg",
      smallheading2: "Implement, monitor and track",
      heading2: "Multiple Policies & Regulations",
      content2:
        "Set distinct attendance and leave policies for different kinds of employees without breaking a sweat! Gain complete business visibility, increase staff accountability, optimise your budgets and procedures with access to real-time data that helps you plan better.",
      img2: "images/roster3.svg",
      smallheading3: "Gain visibility and control with ",
      heading3: "Real-time Productivity Reports",
      content3:
        "Keep an eye on team schedules, timesheets, productivity reports and manpower costs at all times. Access accurate information on your mobile phone on-the-go - anytime, anywhere.",
      img3: "images/inside-page4-img4.svg",
      //   heading4: "Title 1",
      //   content4:
      //     "Las tabs se generan automáticamente a orem ipsum dolor sit amet, consectetur adipiscingrem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri elit.rem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique sed vulputate tincidunt in. Mattis est turpis sit duipartir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.",
      //   img4: "images/inside6.svg",
      titleColor: "rgba(65, 131, 236, 1)",
      class: "tab4",
    },
    {
      id: 5,
      taber: "tab5",
      tabTitle: "Employee Engagement Wall",
      headimg: "images/inside-page5-img1.svg",
      titledescription:
        "The post-pandemic modern workspace now comprises teams and individuals scattered all around the globe. There is a higher need for companies to lean on technology to connect with, listen to, appreciate and reward their workforce. Orgzstack’s Employee Engagement Wall is designed to build better relationships among teams and companies while reducing their chances of disengagement and risks that come with it. ",
      smallheading1: "Keep your workforce up-to-date with",
      heading1: "Regular Announcements",
      content1:
        "Keep your remote and in-office teams in the same loop by choosing a digital space to make important company announcements such as changes in policies and protocols, good news (new client acquisition!) or an emergency update. ",
      img1: "images/inside-page5-img2.svg",
      smallheading2: "Retain your best talent with",
      heading2: "Rewards and Recognitions",
      content2:
        "Let your workforce know how much you truly appreciate them with regular shout-outs, rewards and other forms of feedback. Elevate your employee experience and make them feel valued with the right tools at your disposal. ",
      img2: "images/inside-page5-img3.svg",
      smallheading3: "Build a holistic employee journey with",
      heading3: "Celebrating Together ",
      content3:
        "Bring your workforce closer by providing them a platform designed to celebrate each other’s special days, moments and accomplishments. The closer your teams are, the more they remain engaged, united and motivated for challenges.",
      img3: "images/inside-page5-img4.svg",
      smallheading4: "Keep a finger on the pulse with",
      heading4: "Opinion Polls",
      content4:
        "Wondering how your teams feel about the new policy changes? Want to know how the new increment cycle is being perceived among employees? Host polls and hear your workforce’s voice while offering them anonymity.",
      img4: "images/inside-page5-img5.svg",
      titleColor: "rgba(239, 162, 47, 1)",
      class: "tab5",
    },
    {
      id: 6,
      taber: "tab6",
      tabTitle: "World-class Security",
      headimg: "images/Frame27417.svg",
      titledescription:
        "According to Forbes, 4.1 Billion company records faced data breaches in 2019 alone. It is a high priority for companies to invest in softwares that has the capability of protecting their highly sensitive and confidential data (especially HR data). We’ve built Orgzstack adhering to the highest levels of globally recognised security protocols. We know what the safety of your data means to you.",
      smallheading1: "Advanced server level",
      heading1: "Data Security",
      content1:
        "Our products are hosted with cloud infrastructure providers with SOC 2 Type II and ISO 27001 certifications. The certifications are in addition to dedicated security staff, strictly managed physical access control, and video surveillance.",
      img1: "images/amico1.svg",
      smallheading2: "Quality management with",
      heading2: "ISO 9001 Certification",
      content2:
        "Our main motivation behind acquiring the ISO 9001 certification is quality management of services and ensuring that our customers always get consistent, good-quality products and services.",
      img2: "images/amico2.svg",
      smallheading3: "",
      heading3: "Title 1",
      content3:
        "Las tabs se generan automáticamente aorem ipsum dolor sit amet, consectetur adipiscingrem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri elit.rem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique sed vulputate tincidunt in. Mattis est turpis sit dui partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.",
      img3: "images/amico3.svg",
      //   heading4: "Title 1",
      //   content4:
      //     "Las tabs se generan automáticamente aorem ipsum dolor sit amet, consectetur adipiscingrem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri elit.rem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tri Urna, orci eu volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique sed vulputate tincidunt in. Mattis est turpis sit dui partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.",
      //   img4: "images/inside6.svg",
      titleColor: "rgba(109, 84, 208, 1)",
      class: "tab6",
    },
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
    console.log(currentTab);
  };

  const handleColor = () => {
    if (currentTab === "1") {
      return "tab1";
    }
    if (currentTab === "2") {
      return "tab2";
    }
    if (currentTab === "3") {
      return "tab3";
    }
    if (currentTab === "4") {
      return "tab4";
    }
    if (currentTab === "5") {
      return "tab5";
    }
    if (currentTab === "6") {
      return "tab6";
    }
  };

  const [open, setOpen] = useState(false);
  const [openThank, setOpenThank] = useState(false);

  const handleOpenThank = () => {
    setOpen(false);
    setOpenThank(true);
  };

  const handleCloseThank = () => setOpenThank(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("clicked");

    emailjs
      .sendForm(
        "service_zm3jyj6",
        "template_371fwzz",
        form.current,
        "aFYdqWbYZKjS_PSqY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    handleOpenThank();
  };

  return (
    <div>
      <div className="main-container">
        <div
          className="container"
          style={{
            display: "flex",
            margin: 0,
            paddingRight: 0,
            justifyContent: "center",
          }}
        >
          <div className={handleColor()} style={{ display: "flex" }}>
            <img
              className="fadeshow1 h-20"
              src={require("../TabPage/provider.png")}
              width={120}
              // height={60}
              style={{ right: 30 }}
            />
            <div className="tabs overflow-auto">
              {/* <HorizontalScroll className="tabmain"  > */}
              <div id="slider" className="h-full">
                {tabs.map((tab, i) => (
                  <button
                    key={i}
                    id={tab.id}
                    disabled={currentTab === `${tab.id}`}
                    onClick={handleTabClick}
                    style={{
                      borderBottomColor: tab.titleColor,
                      height: "max-content",
                      width: 170,
                      color: tab.titleColor,
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {tab.tabTitle}
                    {/* <p
                      className="title-main"
                      style={{
                        height: 60,
                        width: 150,
                        color: tab.titleColor,
                        fontSize: 15,
                        fontWeight: 600,
                        margin: 0,
                      }}
                    >
                      {tab.tabTitle}
                    </p> */}
                  </button>
                ))}
              </div>
              {/* </HorizontalScroll> */}
            </div>
          </div>
        </div>

        <div className="hidden1 my-3">
          <div className="flex justify-center">
            <KeyboardDoubleArrowUpSharpIcon fontSize="large" />
          </div>
        </div>

        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === `${tab.id}` && (
                <div>
                  <div className="first-container">
                    <div
                      className="main-heading"
                      style={{
                        color: tab.titleColor,
                        lineHeight: 1.2,
                      }}
                    >
                      {tab.tabTitle}
                    </div>
                    <div className="head-wrapper-img">
                      <img
                        src={tab.headimg}
                        alt="img"
                        className="img-container"
                      />
                      <div>
                        <div className="title-description">
                          {tab.titledescription}
                        </div>
                        <div className="demo-button">
                          <button
                            className="demo-main-button"
                            onClick={handleOpen}
                            style={{ width: 166, height: 45 }}
                          >
                            Request a Demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="second-container">
                    <div className="content-wrap-one">
                      <img src={tab.img1} alt="img" className="img-container" />
                      <div className="content-mini" style={{ margin: 10 }}>
                        <div className="small-heading">{tab.smallheading1}</div>
                        <div className="first-heading">{tab.heading1}</div>
                        <div className="description-one">{tab.content1}</div>
                      </div>
                    </div>
                  </div>
                  <div className="third-container">
                    <div className="content-wrap-two">
                      <img src={tab.img2} alt="img" className="img-container" />
                      <div className="content-mini" style={{ margin: 10 }}>
                        <div
                          className="small-heading"
                          style={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                          }}
                        >
                          {tab.smallheading2}
                        </div>
                        <div
                          className="first-heading"
                          style={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                            marginBottom: 30,
                          }}
                        >
                          {tab.heading2}
                        </div>
                        <div className="description-one">{tab.content2}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="img-break">
          <div>
            <img
              src={require("../TabPage/img-back.png")}
              style={{
                overflow: "hidden",
                width: "100%",
                margin: 0,
              }}
              alt=""
            ></img>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
                flex: 1,
                bottom: 90,
              }}
            >
              <Button
                buttonSize="btn--medium"
                buttonColor="blue"
                onClick={handleOpen}
              >
                Request a Demo
              </Button>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="h-40 w-24">
              <img
                src={require("../TabPage/Character.png")}
                // className='flex justify-center'
                style={{
                  // display: "flex",
                  position: "relative",
                  // justifyContent: "center",
                  // flex: 1,
                  bottom: 170,
                  marginLeft: "-150px",
                  // overflow: "hidden",
                  // width: "100%",
                  // margin: 0,
                }}
                alt=""
              ></img>
            </div>
          </div>
        </div>

        <div className="content -mt-28">
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === `${tab.id}` && (
                <div>
                  <div className="second-container">
                    <div className="content-wrap-one">
                      <img src={tab.img3} alt="img" className="img-container" />
                      <div
                        className="content-mini"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 10,
                        }}
                      >
                        <div
                          className="small-heading"
                          style={{
                            display: "flex",
                          }}
                        >
                          {tab.smallheading3}
                        </div>
                        <div
                          className="first-heading"
                          style={{ display: "flex" }}
                        >
                          {tab.heading3}
                        </div>
                        <div className="description-one">{tab.content3}</div>
                      </div>
                    </div>
                  </div>
                  <div className="third-container">
                    <div className="content-wrap-two">
                      {tab.img4 && (
                        <img
                          src={tab.img4}
                          alt="img"
                          className="img-container"
                        />
                      )}
                      <div className="content-mini" style={{ margin: 10 }}>
                        <div
                          className="small-heading"
                          style={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                          }}
                        >
                          {tab.smallheading4}
                        </div>
                        <div
                          className="first-heading"
                          style={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                          }}
                        >
                          {tab.heading4}
                        </div>
                        <div className="description-one">{tab.content4}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="di-container">
              <div>
                <img src={require("../HomePage/logo_blue.png")} width={250} />
              </div>
              <div className="di-title">Schdeule our Product Demo here</div>
              <div className="text-container">
                <form ref={form} onSubmit={sendEmail}>
                  <div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <TextField
                        required
                        id="standard-basic"
                        label="Full Name"
                        type="text"
                        variant="standard"
                        name="fullName"
                        sx={{
                          ml: 1,
                          mr: 9,
                          mb: 2,
                          mt: 2,
                          flex: 1,
                          width: "40%",
                          color: "rgba(162, 162, 162, 1)",
                          pt: 1,
                          fontSize: 16,
                        }}
                      />
                      <TextField
                        required
                        id="standard-basic"
                        label="Mobile Number"
                        variant="standard"
                        name="mobile"
                        sx={{
                          ml: 1,
                          mt: 2,
                          flex: 1,
                          width: "40%",
                          color: "rgba(162, 162, 162, 1)",
                          pt: 1,
                          fontSize: 16,
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        required
                        id="standard-basic"
                        label="Work E-mail"
                        name="email"
                        type="email"
                        variant="standard"
                        sx={{
                          ml: 1,
                          mr: 9,
                          mb: 2,
                          flex: 1,
                          width: "40%",
                          color: "rgba(162, 162, 162, 1)",
                          pt: 1,
                          fontSize: 16,
                        }}
                      />
                      <TextField
                        required
                        id="standard-basic"
                        label="Designation"
                        variant="standard"
                        name="designation"
                        type="text"
                        sx={{
                          ml: 1,

                          flex: 1,
                          width: "40%",
                          color: "rgba(162, 162, 162, 1)",
                          pt: 1,
                          fontSize: 16,
                        }}
                      />
                    </div>
                    <TextField
                      required
                      id="standard-basic"
                      label="Address"
                      variant="standard"
                      name="address"
                      type="text"
                      sx={{
                        ml: 1,
                        flex: 1,
                        mb: 4,
                        width: "80%",
                        color: "rgba(162, 162, 162, 1)",
                        pt: 1,
                        fontSize: 16,
                      }}
                    />

                    <div style={{ display: "block" }}>
                      <button
                        type="submit"
                        value="Send"
                        id="main-button"
                        style={{ width: 170, height: 57 }}
                      >
                        Request a demo
                      </button>
                      {/* <Button
                        buttonSize="btn--medium"
                        buttonColor="light-blue"
                        type="submit"
                        value="Send"
                      >
                        {buttonLabel_1}
                      </Button> */}
                    </div>
                  </div>

                  {/* <input type="submit" value="Send" /> */}
                </form>
                {/* <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="fullName" />
                    <label>Mobile</label>
                    <input name="mobile" />
                    <label>Email</label>
                    <input type="email" name="email" />
                    <label>Designation</label>
                    <input type="text" name="designation" />
                    <label>Address</label>
                    <textarea name="address" />
                    <input type="submit" value="Send" />
                  </form> */}
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openThank}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="start-end"
              color="inherit"
              onClick={handleCloseThank}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div
              className="di-thank"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div style={{ marginTop: 80 }}>
                <img src={require("../HomePage/Vector.png")} width={150} />
              </div>
              <div
                className="di-title-thank"
                style={{
                  marginTop: 40,
                  fontSize: 20,
                  textAlign: "center",
                  color: "rgba(28, 37, 69, 1)",
                }}
              >
                Thank You !
              </div>
              <div
                className="di-title-thank"
                style={{
                  marginTop: 40,
                  width: 160,
                  fontSize: 20,
                  textAlign: "center",
                  color: "rgba(28, 37, 69, 1)",
                }}
              >
                We will get back to you shortly
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default Tab4;
