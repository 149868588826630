import React from 'react';
import './Footer.css';
import { Button } from '../../../Button';
import { Link } from 'react-router-dom';
import {
    FaFacebook,
    FaInstagram,
    FaYoutube,
    FaTwitter,
    FaLinkedin
  } from 'react-icons/fa';



function Footer() {
  return (
    <div className='footer-container-main' id="footer">
        <div className='footer-container'>
      <section className='footer-subscription'>
        <img src={require('../FooterPage/logoorg.png')} width={200}/>
        <p className='footer-subscription-heading'>
        Next-Gen HRM Solution provider.
        </p>
        
        
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Company</h2>
            <Link to='/about'>About Us</Link>
           
            
           
          </div>
          <div className='footer-link-items'>
            <h2>Support</h2>
           <div className='mb-4'>
           <Link to='/'>Privacy Policy</Link>
           </div>
            
            <div className='mb-2'>
            <Link to='/'>Terms of Services</Link>
            </div>
            
            
          </div>
        
        
          <div className='footer-link-items'>
            <h2>Our Works</h2>
            <Link to='/'>Pricing</Link>
            
            
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <div className='flex items-center mb-2'>
            <FaFacebook color='white' className='flex mr-2'/>
            <a href='https://www.facebook.com/profile.php?id=100089682546438' target="_blank">Facebook</a>
            </div>
            <div className='flex items-center mb-2'>
              <FaInstagram color='white' className='flex mr-2'/>
            <a href='https://www.instagram.com/orgzstack/' target="_blank">Instagram</a>
            </div>
            <div className='flex items-center mb-2'>
              <FaYoutube color='white' className='flex mr-2'/>
            <a href='https://www.youtube.com/channel/UCdQLvF4zfTGosNH4ZMZlmbQ' target="_blank" >Youtube</a>
            </div>
          </div>
        </div>
      </div>
      
        </div>
        <div className='social-media-wrap'>
         
          <small className='website-rights'>All Rights Reserved. Copyright © Informatic Connecting Tech Private Limited
          GST - 27AADCI3759C1ZN</small>
          
        </div>
    </div>
    
  );
}

export default Footer;