import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./Navbar.css";
import { MdFingerprint } from "react-icons/md";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../Button";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
    return window.removeEventListener("resize", showButton);
  }, []);

  return (
    <>
      {/* <IconContext.Provider value={{ color: "#fff" }} > */}

      <nav className="navbar" id="navbar">
        {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'black'}}> */}

        <HashLink
          to="/home#top"
          className="navbar-logo"
          onClick={closeMobileMenu}
        >
          <img src={require("../pages/logoorg.png")} width={170} height={50} />
        </HashLink>
        {/* <div className="emptycontainer" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}></div> */}
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: 20,
          }}
        >
          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <HashLink
                to="/home#top"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                scroll={(el) => {
                  el.scrollIntoView(true);
                  window.scrollBy(0, -64);
                }}
                to="/home#features"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Features
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/home#solutions"
                scroll={(el) => {
                  el.scrollIntoView(true);
                  window.scrollBy(0, -64);
                }}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Solutions
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/home#pricing"
                className="nav-links"
                scroll={(el) => {
                  el.scrollIntoView(true);
                  window.scrollBy(0, -64);
                }}
                onClick={closeMobileMenu}
              >
                Pricing
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/home#contact"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Contact
              </HashLink>
            </li>
           
            <li className="nav-btn">
              {/* {button ? (
                  <Link to="/sign-up" className="btn-link">
                    <Button buttonStyle="btn--outline">SIGN UP</Button>
                  </Link>
                ) : ( */}
              <HashLink
                to="https://app.orgzstack.com/"
                // to="/sign-up"
              >
                <button
                  onClick={closeMobileMenu}
                  className="sign-button"
                  style={{ paddingRight: 0 }}
                >
                  LOGIN
                </button>
              </HashLink>
            </li>
          </ul>
        </div>
        {/* </div> */}
      </nav>
      {/* </IconContext.Provider> */}
    </>
  );
}

export default Navbar;
