import React from "react";

import "./FeatureSection.css";

function FeatureSection({
  topline,
  description,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  minides1,
  minides2,
  minides3,
  minides4,
  minides5,
  minides6,
  alt,
  imgStart,
}) {
  return (
    
      <div className="feature-section" >
        <div className="container" style={{alignItems:'center',display:'flex',alignSelf:'center'}}>
          <div className="row feature-row">
            <div
              className="col-feature-topline"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="topline">{topline}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className="col-feature-description"
            >
              <div className="feature-description-main">{description}</div>
            </div>
            <div
              className="feature-content"
              style={{
                display: "flex",
                flexDirection: imgStart === "start" ? "row-reverse" : "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
                
                alignItems:'center',
                textAlign:'center',
                marginTop:30,
                marginBottom:50
                
              }}
            >
              <div className="feature-one">
                <div className="feature-one-img" style={{display:'flex',justifyContent:'center'}}>
                  <img
                     src={require("../../svg/icon2.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title1}</div>
                <div className="feature-description">{minides1}</div>
              </div>

              <div className="feature-two">
                <div className="feature-two-img" style={{display:'flex',justifyContent:'center'}}>
                  <img
                     src={require("../../svg/icon5.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title2}</div>
                <div className="feature-description">{minides2}</div>
              </div>

              <div className="feature-three">
                <div className="feature-three-img" style={{display:'flex',justifyContent:'center'}}>
                  <img
                   src={require("../../svg/icon6.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title3}</div>
                <div className="feature-description">{minides3}</div>
              </div>

              <div className="feature-four">
                <div className="feature-four-img"  style={{display:'flex',justifyContent:'center'}}>
                  <img
                    src={require("../../svg/icon1.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title4}</div>
                <div className="feature-description">{minides4}</div>
              </div>

              <div className="feature-five">
                <div className="feature-five-img" style={{display:'flex',justifyContent:'center'}}>
                  <img
                    src={require("../../svg/icon3.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title5}</div>
                <div className="feature-description">{minides5}</div>
              </div>

              <div className="feature-six">
                <div className="feature-six-img" style={{display:'flex',justifyContent:'center'}}>
                  <img
                    src={require("../../svg/icon4.svg").default}
                    alt={alt}
                    className="feature-img"
                    width={90}
                  />
                </div>
                <div className="feature-title">{title6}</div>
                <div className="feature-description">{minides6}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
  );
}

export default FeatureSection;
