export const homeObjOne = {
  headline:
    "Next-Gen HRM solutions to Map, Track, Engage and Manage workforce at ease",
  description:
    "Build high-functioning workplaces with simplified, optimised and automated HR functions. Create happier employees with Orgzstack’s enterprise grade HRM solutions designed for modern workplace needs.",
  buttonLabel_1: "Request a Demo",
  buttonLabel_2: "Login",
  imageStart: "",
  img: "images/sample.png",
  alt: "chart",
};
