import React from "react";
import SolutionSection from "./SolutionSection";
import "./SolutionSection.css";
import {
  solutionObjFour,
  solutionObjOne,
  solutionObjThree,
  solutionObjTwo,
  solutionObjFive,
  solutionObjSix,
} from "./SolutionData";
function Solution() {
  return (
    <div id="solutions">
      <div
        className="solution-banner"
        style={{
          background: "#FFF7EC",
          display: "flex",
          height: 120,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img  src={require("../../svg/solprovider.svg").default} width={400} className='main-banner'/>
      </div>
      <SolutionSection {...solutionObjOne} />
      <SolutionSection {...solutionObjTwo} />
      <SolutionSection {...solutionObjThree} />
      <SolutionSection {...solutionObjFour} />
      <SolutionSection {...solutionObjFive} />
      <SolutionSection {...solutionObjSix} />
    </div>
  );
}

export default Solution;
