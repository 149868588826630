import React from "react";
import "./SolutionSection.css";

import { Link } from "react-router-dom";
import { Button } from "../../../Button";
import { keypoints1, keypoints2, keypoints3, keypoints4 } from "./SolutionData";
import { HashLink } from "react-router-hash-link";

function SolutionSection({
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  const toplineColor = () => {
    if (imgStart === "start0" || imgStart === "start2") {
      return "rgba(65, 131, 236, 1)";
    }
    if (imgStart === "start" || imgStart === "start3") {
      return "rgba(239, 162, 47, 1)";
    }
    if (imgStart === "start1" || imgStart === "start4") {
      return "rgba(109, 84, 208, 1)";
    }
  };
  const background = () => {
    if (imgStart === "start0") {
      return "#F9FAFF";
    }
    if (imgStart === "start1") {
      return "#FBF9FF";
    }
    if (imgStart === "start3") {
      return "#FFFBF5";
    }
  };
  return (
    <div
      className="solution-section"
      style={{
        background: background(),
        display: "flex",
        margin: 0,

        width: "auto",
      }}
    >
      <div
        className="container"
        style={{ display: "flex", width: "auto", padding: 0 }}
      >
        <div className="solution-row">
          <div className="solutiom-text-wrapper">
            <div
              className="wraper-full"
              style={{
                display: "flex",
                flexDirection:
                  imgStart === "start0" ||
                  imgStart === "start1" ||
                  imgStart === "start3"
                    ? "row"
                    : "row-reverse",
                justifyContent: "flex-end",
              }}
            >
              <img src={img} alt={alt} className="imgwrap" />
              <div
                className="column-section"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ marginLeft: 0 }}>
                  <h1
                    className="top-line"
                    style={{
                      color: toplineColor(),
                      display: "flex",
                      textAlign: "start",
                      // width: 587,
                      // flexDirection:
                      //   imgStart === "start0" ||
                      //   imgStart === "start1" ||
                      //   imgStart === "start3"
                      //     ? "row-reverse"
                      //     : "row",
                      //     justifyContent:'space-between'
                    }}
                  >
                    {topLine}
                  </h1>
                  <div className="headline" style={{ marginBottom: 20 }}>
                    {headline}
                  </div>
                </div>
                {/* Mobile dashboard report  */}
                <div className="solution-blow">
                  {imgStart === "start0" ? (
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        margin: 0,
                      }}
                      className="solution-points"
                    >
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                          className="solution-point-img"
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(65, 131, 236, 1)",
                              fontWeight: 400,
                            }}
                          >
                            Cloud-based attendance monitoring system
                          </span>{" "}
                          for managing attendance, holiday calendar and shifts.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          margin: 0,
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(65, 131, 236, 1)",
                              fontWeight: 400,
                            }}
                          >
                            Simple interface
                          </span>{" "}
                          to apply for leaves and regularisation requests.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          margin: 0,
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(65, 131, 236, 1)",
                              fontWeight: 400,
                            }}
                          >
                            Single click
                          </span>{" "}
                          leave approval/rejection/regularisation system.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(65, 131, 236, 1)",
                              fontWeight: 400,
                            }}
                          >
                            {" "}
                            A single, secure database
                          </span>{" "}
                          for detailed overviews on employees, structures,
                          hierarchies, with powerful reporting and editing
                          capabilities.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(65, 131, 236, 1)",
                              fontWeight: 400,
                            }}
                          >
                            Standard and custom reports{" "}
                          </span>
                          to stay on top of the attendance data of your
                          employees.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Monitor and Manage On-field Employees */}
                  {imgStart === "start" ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Keep track of{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 400,
                            }}
                          >
                            individual client meetings
                          </span>{" "}
                          undertaken by members of your team.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Input
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            systematic MoMs
                          </span>{" "}
                          (minutes of the meeting) into the system for
                          structuring and routing of information post meetings.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Stay updated on the location of on-field employees,
                          distance travelled during the day and{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            geotagged information
                          </span>{" "}
                          for every meeting.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            Map views
                          </span>
                          for a simplified single-view representation of all
                          meetings done by an employee during the day
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Managing Modern Workspaces - WFH and Remote Employees */}

                  {imgStart === "start1" ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Monitor remote/work from home employees with daily
                          reports consisting of systematically captured
                          <span
                            style={{
                              color: "rgba(101, 81, 178, 1)",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            screenshots, web browsing history and list of
                            applications used.
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Detailed reports on{" "}
                          <span
                            style={{
                              color: "rgba(101, 81, 178, 1)",
                              fontWeight: 500,
                            }}
                          >
                            total productive hours, idle hours, break time{" "}
                          </span>
                          spent and overall work duration for each day.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Roster Management */}

                  {imgStart === "start2" ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Manage your team’s accountability on the go with
                          easy-to-use data on{" "}
                          <span
                            style={{
                              color: "rgba(24, 101, 224, 1)",
                              fontWeight: 500,
                            }}
                          >
                            shifts/attendance across multiple teams,
                          </span>{" "}
                          functions and business units.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Establish{" "}
                          <span
                            style={{
                              color: "rgba(24, 101, 224, 1)",
                              fontWeight: 500,
                            }}
                          >
                            distinct HR policies{" "}
                          </span>{" "}
                          and workplace rules for different types of employees,
                          teams and business units.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Access accurate information on operations including
                          <span
                            style={{
                              color: "rgba(24, 101, 224, 1)",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            schedules, timesheets and reports.
                          </span>{" "}
                          Direct resources based on insights to optimise
                          productivity and resource allocation.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={require("../PricingPage/arrow-blue.svg").default}
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          <span
                            style={{
                              color: "rgba(24, 101, 224, 1)",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            Automated processes
                          </span>{" "}
                          to allocate employees intelligently - based on skill
                          requirement, budgets and more.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Employee Engagement Wall*/}

                  {imgStart === "start3" ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          The social wall enables seamless{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            team communication
                          </span>{" "}
                          and makes sharing of company activities/updates simple
                          and fun.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          It creates a highly{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            interactive, synchronised and happy workplace
                          </span>{" "}
                          by restoring the human connection that is lost among
                          most companies owing to the lockdown.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          It is built to encourage the{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            rewarding and recognising
                          </span>
                          of talent within the company.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          It also acts as an excellent platform among employees
                          to gather{" "}
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            opinions, conduct polls
                          </span>{" "}
                          and have discussions in a fun, yet structured manner.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-orange.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          It acts as a newsfeed for the organisation to make
                          <span
                            style={{
                              color: "rgba(224, 140, 14, 1)",
                              fontWeight: 500,
                            }}
                          >
                            announcements, push updates, wish new and existing
                            employees{" "}
                          </span>
                          on their special days and more.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* World-class Security */}
                  {imgStart === "start4" ? (
                    <div style={{ flexDirection: "column", display: "flex" }}>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Manage your team’s accountability on the go with
                          easy-to-use data on shifts/attendance across multiple
                          teams, functions and business units.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Establish distinct HR policies and workplace rules for
                          different types of employees, teams and business
                          units.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Access accurate information on operations including
                          schedules, timesheets and reports. Direct resources
                          based on insights to optimise productivity and
                          resource allocation.
                        </p>
                      </div>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={
                            require("../PricingPage/arrow-purple.svg").default
                          }
                          width={20}
                          height={20}
                          style={{
                            marginTop: 0,
                            marginLeft: 20,
                            marginRight: 7,
                          }}
                        />
                        <p className="solution-description">
                          Automated processes to allocate employees
                          intelligently - based on skill requirement, budgets
                          and more.
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="learnbtn"
                  // style={{display:'flex',
                  // textAlign:'center',
                  // flexDirection:
                  //       imgStart === "start0" ||
                  //       imgStart === "start1" ||
                  //       imgStart === "start3"
                  //         ? "row-reverse"
                  //         : "row",
                  //         }}
                >
                  {imgStart === "start0" ? (
                    <Link to="/tabs">
                      <HashLink to="/tabs#top">
                        <Button buttonSize="btn--wide" buttonColor="lightblue">
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                  {imgStart === "start2" ? (
                    <Link to="/Tab4">
                      <HashLink to="/tab4#top">
                        <Button buttonSize="btn--wide" buttonColor="lightblue">
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                  {imgStart === "start" ? (
                    <Link to="/Tab2">
                      <HashLink to="/tab2#top">
                        <Button
                          buttonSize="btn--wide"
                          buttonColor="lightyellow"
                        >
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                  {imgStart === "start3" ? (
                    <Link to="/Tab5">
                      <HashLink to="/tab5#top">
                        <Button
                          buttonSize="btn--wide"
                          buttonColor="lightyellow"
                        >
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                  {imgStart === "start1" ? (
                    <Link to="/Tab3">
                      <HashLink to="/tab3#top">
                        <Button
                          buttonSize="btn--wide"
                          buttonColor="lightpurple"
                        >
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                  {imgStart === "start4" ? (
                    <Link to="/Tab6">
                      <HashLink to="/tab6#top">
                        <Button
                          buttonSize="btn--wide"
                          buttonColor="lightpurple"
                        >
                          {buttonLabel}
                        </Button>
                      </HashLink>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionSection;
