import React from "react";
import About from "../AboutPage/About";
import Feature from "../FeaturePage/Feature";
import Footer from "../FooterPage/Footer";
import HomeSection from "../HomeSection";
import Pricing from "../PricingPage/Pricing";
import Solution from "../SolutionPage/Solution";
import Tabs from "../TabPage/Tabs";
import { homeObjOne } from "./Data";
function Home() {
  return (
    <div id='home'>
      <HomeSection {...homeObjOne} />
      <Feature />
      <Solution />
      <Pricing />
    </div>
  );
}

export default Home;
