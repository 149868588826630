export const solutionObjOne = {
  topLine: "Multiple Dashboard Reports",
  headline:
    "Move strategically and proactively with reports that help you do better. Orgzstack constantly converts data to generate instant real-time reports on everything from workforce planning to approval tracking.",

  buttonLabel: "Learn More >>",
  imgStart: "start0",
  img: "images/homepic3.svg",
  alt: "",
};

export const solutionObjTwo = {
  topLine: "Monitor and Manage On-field Employees",
  headline:
    "Get a hold over your employees' activities, especially when they are on-field. Find out how they spend their productive time, whether at the office, on-field, in a client meeting or working remotely.",
  buttonLabel: "Learn More >>",
  imgStart: "start",
  img: "images/homepic4.svg",
  alt: "",
};
export const solutionObjThree = {
  topLine: "Managing Modern Workspaces - WFH and Remote Employees",
  headline:
    "Stay on top of remote employees with in-depth reports on how they utilise their time on apps and websites and monitor their performance by identifying active vs. idle time.",
  buttonLabel: "Learn More >>",
  imgStart: "start1",
  img: "images/homepic6.svg",
  alt: "",
};

export const solutionObjFour = {
  topLine: "Roster Management",
  headline:
    "Improve productivity, staff accountability and optimise operations by digitising shift scheduling and timesheet consolidation with Orgzstack’s Roster Management.",
  buttonLabel: "Learn More >>",
  imgStart: "start2",
  img: "images/homepic5.svg",
  alt: "",
};
export const solutionObjFive = {
  topLine: "Employee Engagement Wall",
  headline:
    "Orgzstack functions as more than just another HRMS platform. It has a social wall designed to amplify employee engagement, talent development, retention and more. It helps employees stay connected with one another and the organisation.",

  buttonLabel: "Learn More >>",
  imgStart: "start3",
  img: "images/homepic8.svg",
  alt: "",
};

export const solutionObjSix = {
  topLine: "World-class Security",
  headline:
    "Orgzstack meets industry best security practices in every aspect of handling data whether it is for storing, transferring, accessing, backing up or monitoring.",

  buttonLabel: "Learn More >>",
  imgStart: "start4",
  img: "images/homepic7.svg",
  alt: "",
};

export const keypoints1 = {
  point1:
    "Cloud-based attendance monitoring system for managing attendance, holiday calendar and shifts.",
  point2: "Simple interface to apply for leaves and regularisation requests.",
  point3: "Single click leave approval/rejection/regularisation system. ",
  point4:
    "A single, secure database for detailed overviews on employees, structures, hierarchies, with powerful reporting and editing capabilities.",
  point5:
    "Standard and custom reports to stay on top of the attendance data of your employees.",
};

export const keypoints2 = {
  point1:
    "Keep track of individual client meetings undertaken by members of your team.",
  point2:
    "Input systematic MoMs (minutes of the meeting) into the system for structuring and routing of information post meetings.",
  point3:
    "Stay updated on the location of on-field employees, distance travelled during the day and geotagged information for every meeting.",
  point4:
    "Map views for a simplified single-view representation of all meetings done by an employee during the day",
};

export const keypoints3 = {
  point1:
    "Monitor remote/work from home employees with daily reports consisting of systematically captured screenshots, web browsing history and list of applications used.",
  point2:
    "Detailed reports on total productive hours, idle hours, break time spent and overall work duration for each day.",
};

export const keypoints4 = {
  point1:
    "Manage your team’s accountability on the go with easy-to-use data on shifts/attendance across multiple teams, functions and business units.",
  point2:
    "Establish distinct HR policies and workplace rules for different types of employees, teams and business units.",
  point3:
    "Access accurate information on operations including schedules, timesheets and reports. Direct resources based on insights to optimise productivity and resource allocation. ",
  point4:
    "Automated processes to allocate employees intelligently - based on skill requirement, budgets and more.",
};
