import React from "react";
import { featureObjOne } from "./FeatureData";
import FeatureSection from "./FeatureSection";
function Feature() {
  return (
    <div id="features">
      <FeatureSection {...featureObjOne} />
    </div>
  );
}

export default Feature;
