import React from "react";
import "./About.css";

function About() {
  return (
    <div className="main-container" style={{marginLeft:20,marginTop:100}}>
      <div className="main-title" style={{display:"flex"}}>About Us</div>
      <div className="main-wrapper" style={{ display: "flex",flexDirection:'row',justifyContent:"space-between" }}>
        <div
          className="description-main"
          style={{ fontSize: 22,fontWeight:400,marginRight:50 }}
        >
        <div style={{marginBottom:10}}> Welcome to Orgzstack! We are a leading provider of comprehensive Human Resource Management System (HRMS) solutions designed to streamline and automate HR processes for businesses of all sizes. With our cutting-edge technology and extensive industry experience, we aim to revolutionize the way organizations manage their workforce.</div> 

        <div style={{marginBottom:10}}>  At Orgzstack, we understand the challenges faced by HR professionals in today's fast-paced business environment. That's why we have developed a robust and intuitive HRMS platform that empowers HR departments to effectively manage their employees' lifecycle from recruitment to retirement.</div>

        <div style={{marginBottom:10}}>Our HRMS software offers a wide range of modules and features tailored to meet the unique needs of each organization. Whether you need assistance with applicant tracking, employee onboarding, time and attendance management, leave and absence management, performance evaluation, or payroll processing, our solution has got you covered. Our goal is to simplify HR processes, improve efficiency, and enhance employee engagement, resulting in better overall organizational performance.</div>
<div style={{marginBottom:10}}> What sets us apart is our commitment to delivering exceptional customer service. We believe in building long-term partnerships with our clients, understanding their specific requirements, and providing personalized support every step of the way. Our dedicated team of experts is always available to address any questions or concerns, ensuring a smooth implementation and ongoing usage of our HRMS software.</div>
        </div>
        <img src={require("../AboutPage/amico.png")} width={500} style={{marginRight:30}} />
      </div>
      {/* <div className="description-one" style={{marginRight:40,marginTop:90,fontWeight:400,display:'flex'}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu
        volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique
        sed vulputate tincidunt in. Mattis est turpis sit dui porta risus. Lorem
        ipsum dolor sit amet, consectetur adipiscing elit. Urna, orci eu
        volutpat bibendum. Aliquam, velit enim, ut odio. Aliquet ut tristique
        sed vulputate tincidunt in. Mattis est turpis sit dui porta risus.
      </div> */}
      <div className="description-two" style={{marginRight:40,marginTop:70,fontWeight:400}}>
      

      <div style={{marginBottom:10}}>Security and data privacy are of utmost importance to us. We employ the latest industry standards and best practices to ensure the confidentiality and integrity of your sensitive HR data. Our software is hosted on secure servers and undergoes regular vulnerability assessments to protect against potential threats.</div>

      <div style={{marginBottom:10}}>At Orgzstack, we are passionate about helping organizations optimize their HR processes, drive employee engagement, and achieve their strategic objectives. Our HRMS software is designed to grow with your business and adapt to your evolving needs. With our user-friendly interface and customizable features, you can tailor the system to align with your unique workflows and business rules.</div>

      <div style={{marginBottom:10}}>Join us on this transformative journey and experience the power of our HRMS software. Contact us today to schedule a demo and learn how our solution can revolutionize your HR operations and drive your organization towards success</div>

      </div>
      <div className="img-container" style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:90,marginBottom:90}}>
        <img src={require("../AboutPage/bro.png")} width={500} />
      </div>
    </div>
  );
}

export default About;
