export const featureObjOne = {
  topline: "why Orgzstack",
  description:
    "Gain full control over your entire personnel data in one place. Manage people and their jobs at your fingertips on any device, anywhere. ",
  imageStart: "",
  img1: "images/img1.png",
  img2: "images/img2.png",
  img3: "images/img3.png",
  img4: "images/img4.png",
  img5: "images/img5.png",
  img6: "images/img6.png",
  title1: "World Class UI & UX",
  title2: "All-Embracive Workforce",
  title3: "Manage complex business structures and rules at ease",
  title4: "Complete Solution on Mobile and Web",
  title5: "Comprehensive Team Management",
  title6: "All your Key Data in One View",
  minides1:'Orgzstack’s intuitive design and seamless experience helps organisations to get started right away, saving the time usually spent on extensive training sessions.',
  minides2:'Easily establish HR policies and work protocols for each employee format separately - in-office, work from home, hybrid, on-side and on-road. ',
  minides3:'Establish multiple scenarios customisable as per teams, offices and business units across multiple organisations on one software. ',
  minides4:'Orgzstack’s complete set of features can be easily accessed on all devices - mobile, tablet, laptop or desktop computer. ',
  minides5:'A fully featured HRM solution to optimise the everyday functions of HR - productivity management, employee tracking, approvals and regularisations, company news feed, etc.',
  minides6:'Find employee profiles, individual and team hierarchies, reporting structures, data on individual and team productivity and data-based insights at an individual, team and organisational  and  cross-organisational level - all in one place.',
};
