import React, { useState, useRef } from "react";
import { Button } from "../../Button";
import { Link } from "react-router-dom";
import "./HomeSection.css";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
// import AccountCircle from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { HashLink } from "react-router-hash-link";
import axios from "axios";

import emailjs from "@emailjs/browser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  height: 490,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

function HomeSection({
  headline,
  description,
  buttonLabel_1,
  buttonLabel_2,
  img,
  alt,
  imgStart,
}) {
  const [open, setOpen] = useState(false);
  const [openThank, setOpenThank] = useState(false);

  const handleOpenThank = () => {
    setOpen(false);
    setOpenThank(true);
  };

  const handleCloseThank = () => setOpenThank(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const imageData = [
    {
      id: 1,
      img: "images/homepic1.svg",
    },
    {
      id: 2,
      img: "images/homepic2.svg",
    },
  ];

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("clicked");

    emailjs
      .sendForm(
        "service_zm3jyj6",
        "template_371fwzz",
        form.current,
        "aFYdqWbYZKjS_PSqY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    handleOpenThank();
  };

  // const [change, setChange] = useState(" ");

  // const handleChange = (e) => {
  //   // console.log(e.target.name, e.target.value);
  //   setChange({
  //     ...change,
  //     [e.target.name]: [e.target.value],
  //   });
  //   // console.log(change);
  // };
  const [email,setEmail] = useState('');
  const [name, setName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [designation, setDesignation] = useState();
  const [workEmail, setWorkEmail] = useState(email != null ? email : '');
  
  const [address, setAddress] = useState();

  const handleName = (e) => {
    // console.log(e.target.value);
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    // console.log(e.target.value);
    setEmail(e.target.value);
  };

  const emailLength = email.length;
  const handleMobileNumber = (e) => {
    // console.log(e.target.value);
    setMobileNumber(e.target.value);
  };

  const handleDesignation = (e) => {
    // console.log(e.target.value);
    setDesignation(e.target.value);
  };

  const handleWorkEmail = (e) => {
    // console.log(e.target.value);
    setWorkEmail(   e.target.value);
  };

  const handleAddress = (e) => {
    // console.log(e.target.value);
    setAddress(e.target.value);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // console.log(change);

    axios
      .post("https://api.orgzstack.com/v1/enquiry/enquiry", {
        name: name,
        mobileNumber: mobileNumber,
        designation: designation,
        workEmail: workEmail,
        address: address,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div
        className="home_section"
        style={{
          position: "relative",
          background: "#F9FAFF",
          backgroundImage: "url(./homebackgroud.svg)",
          width: "100%",
        }}
      >
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              maxWidth={420}
              maxHeight={550}
              sx={style}
              className="box-contain"
            >
              <div className="di-container">
                <div>
                  <img src={require("./HomePage/logo_blue.png")} width="50%" />
                </div>
                <div className="di-title">Schdeule our Product Demo here</div>
                <div className="text-container">
                  <form ref={form} onSubmit={sendEmail}>
                    <div>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <TextField
                          required
                          id="standard-basic"
                          label="Full Name"
                          type="text"
                          variant="standard"
                          name="fullName"
                          // value={name}
                          onChange={handleName}
                          sx={{
                            ml: 1,
                            mr: 9,
                            mb: 2,
                            mt: 2,
                            flex: 1,
                            width: "40%",
                            color: "rgba(162, 162, 162, 1)",
                            pt: 1,
                            fontSize: 16,
                          }}
                        />
                        <TextField
                          required
                          id="standard-basic"
                          label="Mobile Number"
                          variant="standard"
                          name="mobile"
                          // value={mobileNumber}
                          onChange={handleMobileNumber}
                          sx={{
                            ml: 1,
                            mt: 2,
                            flex: 1,
                            width: "40%",
                            color: "rgba(162, 162, 162, 1)",
                            pt: 1,
                            fontSize: 16,
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <TextField
                          required
                          id="standard-basic"
                          label="Work E-mail"
                          name="email"
                          type="email"
                          variant="standard"
                           value={emailLength >=1 ? email : workEmail}
                          onChange={handleWorkEmail}
                          sx={{
                            ml: 1,
                            mr: 9,
                            mb: 2,
                            flex: 1,
                            width: "40%",
                            color: "rgba(162, 162, 162, 1)",
                            pt: 1,
                            fontSize: 16,
                          }}
                        />
                        <TextField
                          required
                          id="standard-basic"
                          label="Designation"
                          variant="standard"
                          name="designation"
                          type="text"
                          // value={designation}
                          onChange={handleDesignation}
                          sx={{
                            ml: 1,

                            flex: 1,
                            width: "40%",
                            color: "rgba(162, 162, 162, 1)",
                            pt: 1,
                            fontSize: 16,
                          }}
                        />
                      </div>
                      <TextField
                        required
                        id="standard-basic"
                        label="Address"
                        variant="standard"
                        name="address"
                        type="text"
                        // value={address}
                        onChange={handleAddress}
                        sx={{
                          ml: 1,
                          flex: 1,
                          mb: 4,
                          width: "80%",
                          color: "rgba(162, 162, 162, 1)",
                          pt: 1,
                          fontSize: 16,
                        }}
                      />

                      <div style={{ display: "block" }}>
                        <button
                          type="submit"
                          value="Send"
                          id="main-button"
                          onClick={handleSubmit}
                          style={{ width: 170, height: 57 }}
                        >
                          Request a demo
                        </button>
                        {/* <Button
                        buttonSize="btn--medium"
                        buttonColor="light-blue"
                        type="submit"
                        value="Send"
                      >
                        {buttonLabel_1}
                      </Button> */}
                      </div>
                    </div>

                    {/* {response && <p>Response: {JSON.stringify(response)}</p>}
                    {error && <p>Error: {error.message}</p>} */}

                    {/* <input type="submit" value="Send" /> */}
                  </form>
                  {/* <form ref={form} onSubmit={sendEmail}>
                    <label>Name</label>
                    <input type="text" name="fullName" />
                    <label>Mobile</label>
                    <input name="mobile" />
                    <label>Email</label>
                    <input type="email" name="email" />
                    <label>Designation</label>
                    <input type="text" name="designation" />
                    <label>Address</label>
                    <textarea name="address" />
                    <input type="submit" value="Send" />
                  </form> */}
                </div>
              </div>
            </Box>
          </Modal>
        </div>

        <Modal
          onClose={handleCloseThank}
          open={openThank}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <IconButton
              edge="start-end"
              color="inherit"
              onClick={handleCloseThank}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <div
              className="di-thank"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div style={{ marginTop: 20 }}>
                <img src={require("./HomePage/Vector.png")} width={150} />
              </div>
              <div
                className="di-title-thank"
                style={{
                  marginTop: 40,
                  fontSize: 20,
                  textAlign: "center",
                  color: "rgba(28, 37, 69, 1)",
                }}
              >
                Thank You !
              </div>
              <div
                className="di-title-thank"
                style={{
                  marginTop: 40,
                  width: 160,
                  fontSize: 20,
                  textAlign: "center",
                  color: "rgba(28, 37, 69, 1)",
                }}
              >
                We will get back to you shortly
              </div>
            </div>
          </Box>
        </Modal>

        <div className="row home-row">
          <div className="col">
            <div className="home-text-wraper">
              {/* {imageData.map((image, i) => ( */}
              <div className="main-wrap">
                <div className="small-wrap">
                  <h1 className="heading"> {headline}</h1>
                  <div style={{ display: "flex" }}>
                    <div className="home-btn">
                      <Link to="./" className="home-btn1">
                        <Button
                          buttonSize="btn--medium"
                          buttonColor="blue"
                          onClick={handleOpen}
                        >
                          {buttonLabel_1}
                        </Button>
                      </Link>
                      <a href="https://app.orgzstack.com/">
                        <Button
                          buttonStyle="btn--outline"
                          buttonSize="btn--medium"
                        >
                          {buttonLabel_2}
                        </Button>
                      </a>
                    </div>
                  </div>

                  <div className="img-des">
                    <div className="home-img-wrapper">
                      <img
                        src={require("../svg/homepic2.svg").default}
                        alt={alt}
                        className="home-img"
                        width={330}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="left-img"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <img
                    src={require("../svg/homepic1.svg").default}
                    // src={image.img}
                    // width={900}
                    className="home-left-img"
                    style={{ marginLeft: 10 }}
                  />
                  <div className="home-description" style={{ display: "flex" }}>
                    {description}
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>

        <div className="home-bottom">
          <div className="bottom-req">
            <MailOutlineIcon
              sx={{ color: "action.active", mr: 1, ml: 2, my: 0.5 }}
              className="mail-button"
            />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Enter your email-id here"
              className="home-input"
             value={emailLength ? email : workEmail}
              onChange={handleEmail}
            />

            <Link to="./" className="home-btn3">
              <Button
                buttonSize="btn--medium"
                buttonColor="blue"
                onClick={handleOpen}
              >
                {buttonLabel_1}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeSection;
