import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import React from "react";
import About from "./component/pages/AboutPage/About";
import Feature from "./component/pages/FeaturePage/Feature";
import Footer from "./component/pages/FooterPage/Footer";
import Home from "./component/pages/HomePage/Home";
import HomeSection from "./component/pages/HomeSection";
import Navbar from "./component/pages/Navbar";
import Pricing from "./component/pages/PricingPage/Pricing";

import Solution from "./component/pages/SolutionPage/Solution";
import Tabs from "./component/pages/TabPage/Tabs";
import Tab4 from "./component/pages/TabPage/Tab4";
import Tab2 from "./component/pages/TabPage/Tab2";
import Tab5 from "./component/pages/TabPage/Tab5";
import Tab6 from "./component/pages/TabPage/Tab6";
import Tab3 from "./component/pages/TabPage/Tab3";

function App() {
  return (
    <Router>
      <Navbar />

      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/features" element={<Feature />} />
        <Route path="/solutions" element={<Solution />} />
        <Route path="/pricing" element={<Pricing />} />
        {/* <Route path="/contact" element={<Footer />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/tabs" element={<Tabs />} />
        {/* <Route path=":id" element={<Tabs />} /> */}
        <Route path="/Tab4" element={<Tab4 />} />
        <Route path="/Tab2" element={<Tab2 />} />
        <Route path="/Tab5" element={<Tab5 />} />
        <Route path="/Tab6" element={<Tab6 />} />
        <Route path="/Tab3" element={<Tab3 />} />
      </Routes>
      <Footer />
    </Router>
    // <div>
    //   <Navbar />
    //   <Home />
    //   <Feature />
    //   <Solution />
    //   <Pricing />
    //   <Footer />
    // </div>
  );
}

export default App;
